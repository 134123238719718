.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  display: flex;
  pointer-events: none;
  padding-bottom: 50px;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #ED6EC1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.footer-container {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 0;
  left: 0;
  padding-top: 95px;
  padding-bottom: 45px;
}

.twitter-logo {
  width: 35px;
  height: 35px;
}

.footer-text {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.cta-button {
  height: 45px;
  border: 0;
  width: 270px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.cta-button-grey {
  height: 45px;
  border: 0;
  width: 270px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: rgb(187, 184, 184);
  text-shadow: 0 0 8px #000;
}

.connect-wallet-button {
  background: -webkit-linear-gradient(left, rgb(122, 122, 122), rgb(156, 156, 156));
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  pointer-events: none;
  cursor: not-allowed;
}

.header-img {
  display: flex;
  padding-bottom: 50px;
}

.join-button {
  background: -webkit-linear-gradient(left, #fc06fc, #1100ff);
  background-size: 100% 100%;
  animation: gradient-animation 4s ease infinite;
  margin-top: 10px;
  margin-bottom: 10px;
}

.whitepaper-button {
  background: -webkit-linear-gradient(left, #4e44ce, #35aee2);
  background-size: 100% 100%;
  animation: gradient-animation 4s ease infinite;
  margin-top: 10px;
  margin-bottom: 10px;
}

.buy-button {
  background: -webkit-linear-gradient(left, #4e44ce, #35aee2);
  background-size: 100% 100%;
  animation: gradient-animation 4s ease infinite;
  /* margin-top: 10px; */
  margin-bottom: 10px;
}

.Sticky-img {
  height: 75vmin;
  pointer-events: none;
  display: flex;
  padding-top: 30px;
  /* position: absolute; */
  /* justify-content: center; */
  align-items: center;
  align-self: center;
}